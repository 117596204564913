* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}
body {
  font-family: "Source Code Pro", monospace;
  margin: 0;
  font: normal 75% Arial, Helvetica, sans-serif;
  position: relative;
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
}

/* ---- reset ---- */

/* canvas {
  display: block;
  vertical-align: bottom;
} */

/* ---- particles.js container ---- */

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #b61924;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.home {
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.main-home h1 {
  font-size: 4rem;
}

.main-home h3 {
  font-size: 1.5rem;
}

.menu-btn {
  width: 32px;
  position: absolute;
  z-index: 4;
  font-size: 32px;
  color: #333;
  margin: 20px 20px 0;
  cursor: pointer;
  list-style-type: none;
  left: 0;
  top: 0;
}

ul.drop-menu {
  position: relative;
  top: -20%;
  left: 20;
  width: 150px;
  padding: 0;
}
ul.drop-menu li {
  text-align: center;
  width: 150px;
  height: 50px;
  line-height: 45px;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  background: #333;
  cursor: pointer;
}
ul.drop-menu li:hover {
  z-index: 4;
  opacity: 0.7;
  background: #413f3f;
}

ul.drop-menu li a {
  color: #fff;
  text-decoration: none;
}

ul.drop-menu li {
  display: none;
}

.menu-btn:hover > ul.drop-menu li {
  display: block;
}

.menu-btn:hover > ul.drop-menu {
  perspective: 1000px;
}
.menu-btn:hover > ul.drop-menu li {
  opacity: 0;
  transform-origin: top center;
}
.menu-btn:hover > ul.drop-menu li:nth-child(1) {
  animation-name: menu1;
  animation-duration: 300ms;
  animation-delay: 25ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.menu-btn:hover > ul.drop-menu li:nth-child(2) {
  animation-name: menu1;
  animation-duration: 300ms;
  animation-delay: 250ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.menu-btn:hover > ul.drop-menu li:nth-child(3) {
  animation-name: menu1;
  animation-duration: 300ms;
  animation-delay: 475ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.menu-btn:hover > ul.drop-menu li:nth-child(4) {
  animation-name: menu1;
  animation-duration: 300ms;
  animation-delay: 700ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.menu-btn:hover > ul.drop-menu li:nth-child(5) {
  animation-name: menu1;
  animation-duration: 300ms;
  animation-delay: 925ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes menu1 {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  60% {
    transform: rotateX(40deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

.about h1 {
  margin: 160px;
  font-size: 60px;
}

.btn {
  font-family: "Source Code Pro", monospace;
  margin: 20px;
  background: none;
  border: none;
  padding: 12px 20px;
  min-width: 200px;
  cursor: pointer;
  transition: color 0.4s linear;
  position: relative;
  font-size: large;
  outline: none;
}
.btn:hover {
  color: #fff;
}
.btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: -1;
  transition: transform 0.5s;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
}
.btn1::before {
  transform: scaleX(0);
}
.btn2::before {
  transform: scaleY(0);
}
.btn3::before {
  transform: scaleX(0);
}
.btn:hover::before {
  transform: scaleX(1);
}

/* About */
.about-back a i,
.skill-back a i {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 30px;
  cursor: pointer;
  transform: 0.2s;
  color: #000;
  z-index: 1;
}
.fill:hover .far,
.fill .fas {
  display: none;
  /* transition: 0.4s; */
}
.fill:hover .fas {
  display: inline;
  transition: 0.4s;
}
.about-image img {
  height: 150px;
  border-radius: 50%;
  margin: 40px;
}
.about {
  margin: 40px;
  width: 50%;
  text-align: center;
  line-height: 1.6rem;
  letter-spacing: 0.075rem;
  font-weight: 500;
  font-family: "Gordita", sans-serif;
}
a {
  text-decoration: none;
  color: #000;
  text-align: center;
}

/* Skills */
.skills {
  margin-top: 40px;
}
.container-skills {
  width: 75%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.skill {
  width: 140px;
  height: 200px;
  background: #f8f198;
  color: #000;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.icon {
  color: #000;
  padding: 25px;
  font-size: 2rem;
  border-bottom: 0.5px solid #888;
}
.content {
  width: 80%;
  text-align: center;
  margin-top: 20px;
}
.content p {
  margin: 5px;
  font-size: small;
}
.content p:nth-child(2) {
  font-size: x-small;
}
.container-skills a {
  text-decoration: none;
  text-align: center;
}

/* Portfolio */
.portfolio {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card {
  margin-top: 20px;
  cursor: pointer;
  width: 340px;
  height: 191px;
  border-radius: 5px;
}
.front,
.back {
  width: 340px;
  height: 191px;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.6s linear;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.front img {
  height: 100%;
}
.front {
  transform: perspective(600px) rotateY(0deg);
}
.back {
  background: #fff;

  transform: perspective(600px) rotateY(180deg);
}
.back-content {
  color: #212121;
  text-align: center;
  width: 100%;
}
.back-content h2 {
  margin-bottom: 25px;
  margin-top: 10px;
}
.sm {
  margin: 20px 0;
}
.sm a {
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 18px;
  transition: 0.4s;
  border-radius: 50%;
  color: #212121;
}
.sm a:hover {
  background: #000;
  color: #fff;
}
.card:hover > .front {
  transform: perspective(600px) rotateY(-180deg);
}
.card:hover > .back {
  transform: perspective(600px) rotateY(0deg);
}
span {
  font-size: small;
}

/* Contact */
.contact {
  margin-top: 25px;
  width: 360px;
  height: 95%;
  text-align: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.info {
  background: #000;
  color: #fff;
  padding: 8px;
}
.info h3 {
  margin-top: 8px;
}
.info ul {
  margin-top: 10px;
  list-style: none;
  font-size: small;
}
.info ul li {
  margin-top: 10px;
}
.info ul li a {
  color: #fff;
}
.info .sm a {
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
  transition: 0.4s;
  border-radius: 50%;
}
.info .sm a:hover {
  background: #fff;
  color: #000;
}
.form {
  padding: 30px;
}
.input {
  padding: 10px;
}
#name,
#phone,
#email {
  width: 180px;
  height: 35px;
  border: 1px solid #888;
  border-radius: 5px;
  padding: 5px;
}
#message {
  width: 250px;
  height: 100px;
  border: 1px solid #888;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  resize: none;
}
#name:focus,
#phone:focus,
#email:focus,
#message:focus {
  border: 1px solid #000;
}
#name {
  margin-left: 10px;
}
#submit {
  margin-top: 10px;
  background: #000;
  color: #fff;
  border: none;
  width: 120px;
  height: 40px;
  border-radius: 2.5px;
}
#submit:hover {
  transition: 0.7s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #fff;
  color: #000;
}

/* Media */
@media screen and (max-width: 768px) {
  .portfolio {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn {
    width: 120px;
    height: 50px;
    background: #000;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .about-back a i,
  .skill-back a i {
    font-size: 20px;
  }
  .about-me {
    margin-top: 40px;
  }
  .about {
    width: 80%;
    text-align: center;
    font-size: 0.8rem;
  }
  .skill {
    width: 220px;
  }
  footer {
    font-size: xx-small;
  }
}
